import React, { Component, Fragment, useState, useEffect, } from 'react';
import { useToggle, useAsync, useMap, } from 'react-use';
import { isEmpty, get, orderBy, sumBy, groupBy, keyBy, sortBy, omit, } from 'lodash';
import { Button } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { toast } from 'react-toastify';

import { batch } from '../../shared/firebase';

import firebase, { functions } from '../../firebase';
import { numberFormat, floatFormat, } from '../../util';
import { withConsolidationAccountItem, } from '../../shared/models/trial';
import { getCategory, } from '../../shared/models/accountItem';
import { changeFieldNames, fields, } from '../../shared/models/allowance';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCompanySelector from '../hooks/useCompanySelector';
import useQueryParams from '../hooks/useQueryParams';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import ModelFormModal from '../modals/ModelFormModal';
import CompanySyncButton from '../CompanySyncButton';
import TrialsSyncButton from '../TrialsSyncButton';
import ProgressButton from '../ProgressButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import QuerySelector from '../QuerySelector';
import AmountWithExchange from '../AmountWithExchange';
import IndividualChangesTableSection from '../IndividualChangesTableSection';
import Alerts from '../Alerts';

const { keys, entries } = Object;
const db = firebase.firestore();

export default RelatedCompanyPage(function CompanyAllowances (props) {
  const { relatedCompany, company, subsidiaryId, period, yearMonth, prevEndYearMonth, prevCr, ar, cr, isLockedMonth, startYearMonth, } = props;
  const [state, { set: setState }] = useMap({});
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [company, subsidiaryId]);
  const accountItemsById = keyBy(accountItems, 'id');
  const itemTrials = useCollectionSubscription(company.ref.collection('itemTrials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);
  const trials = useCollectionSubscription(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [subsidiaryId, yearMonth]);
  const startYearMonthTrials = useCollectionSubscription(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', startYearMonth), [subsidiaryId, startYearMonth]);
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const alerts = entries(state).map(([sectionName, items]) => {
    return items.some(_ => floatFormat(_.closing) !== floatFormat(_.trialAmount)) && `差額が発生しています`;
  }).filter(_ => _);

  return props.translate(
    <div className="company-allowances">
      <Alerts alerts={alerts} />
      <IndividualChangesTableSection
        section="引当金"
        title="増減"
        filterTrials={_ => _.account_item_name && _.consolidationAccountItem?.group_name?.includes('引当金')}
        collectionName="allowances"
        fields={fields}
        changeFieldNames={changeFieldNames}
        onLoadItems={_ => setState('引当金', _)}
        {...{ ...props, accountItems, consolidationAccountItems, itemTrials, trials, startYearMonthTrials, }}
      />
    </div>
  );
});
