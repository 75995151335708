const { isEmpty, get, last } = require('lodash');

const { accountItemCategoriesByName, } = require('../config');

const { entries } = Object;

const isTrialNetIncome0 = trialBalances => trialBalances.find(_ => _.account_category_name === '当期純損益金額')?.closing_balance === 0;
const withConsolidationAccountItem = (trialBalance, accountItemsByName, consolidationAccountItemsById) => {
  const accountItem = accountItemsByName[trialBalance.account_item_name];
  const consolidationAccountItem = consolidationAccountItemsById[accountItem?.consolidationAccountItemId];
  return {
    ...trialBalance,
    accountItem,
    consolidationAccountItem,
  };
};

const balanceFields = ({ accountItems, }) => {
  const accountItemNames = accountItems.map(_ => _.name);
  return {
    account_item_name: {
      type: 'string',
      validations: {
        exists: v => isEmpty(v) || accountItemNames.includes(v),
      },
    },
    closing_balance: {
      type: 'float',
      validations: {
        finite: v => isFinite(v),
      },
    },
  };
};

exports.balanceFields = balanceFields;
exports.isTrialNetIncome0 = isTrialNetIncome0;
exports.withConsolidationAccountItem = withConsolidationAccountItem;
