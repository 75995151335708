const { pickBy, isEmpty, omit, } = require('lodash');
const { isEmail, } = require('validator');

const { screens, pkgScreens, } = require('../config');
const { roles, } = require('./user');

const { keys, entries } = Object;

const fields = ({ company, isSelf, currentRole, relatedCompanies, } = {}) => {
  return {
    role: {
      label: '権限',
      type: 'select',
      options: entries((isSelf || currentRole === 'owner') ? roles : omit(roles, ['owner'])).map(([k, v]) => ({ label: v, value: k, })),
      validations: {
        required: v => !isEmpty(v),
      },
      readOnly: _ => currentRole === 'owner',
    },
    enabledScreens: {
      label: 'アクセス可能な画面',
      type: 'multiSelect',
      options: (s) => {
        const availableScreens = omit(screens, 'settings');
        return entries(availableScreens).map(([k, v]) => ({ label: v.label, value: k, }));
      },
      hidden: s => !['member', 'reader'].includes(s.role),
    },
    enabledAllRelatedCompanies: {
      label: '全ての会社にアクセス可能',
      type: 'boolean',
      initialValue: false,
      hidden: s => !['member', 'reader'].includes(s.role) || !s.enabledScreens?.includes('relatedCompanies'),
    },
    enabledRelatedCompanyIds: {
      label: 'アクセス可能な会社',
      type: 'multiSelect',
      options: relatedCompanies.map(_ => ({ label: _.display_name, value: _.id })),
      hidden: s => s.enabledAllRelatedCompanies || !['member', 'reader'].includes(s.role) || !s.enabledScreens?.includes('relatedCompanies'),
    },
    enabledPkgScreens: {
      label: 'アクセス可能な全社連結PKG画面',
      type: 'multiSelect',
      options: (s) => {
        const availableScreens = omit(pkgScreens, 'settings');
        return entries(availableScreens).map(([k, v]) => ({ label: v.label, value: k, }));
      },
      hidden: s => !['member', 'reader'].includes(s.role) || !s.enabledScreens?.includes('pkg'),
    },
    approvable: {
      label: '仕訳承認できる',
      type: 'boolean',
      initialValue: false,
      hidden: s => !['member'].includes(s.role),
    },
  };
};

const addingFields = (...args) => {
  return {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
      },
    },
    ...fields(...args),
    role: {
      ...fields(...args).role,
      options: fields(...args).role.options.filter(_ => _.value !== 'owner'),
    },
  };
};

exports.fields = fields;
exports.addingFields = addingFields;
