import React, { useState, Fragment, useEffect, } from 'react';
import { useToggle, useAsync, } from 'react-use';
import { countBy, isEqual, sumBy, pick, isEmpty, groupBy, get, range, inRange, keyBy, sortBy, omit, round, } from 'lodash';
import { Button, FormGroup, Input, Label, } from 'reactstrap';
import Select from 'react-select';
import { endOfMonth, parse as parseDate, format as formatDate } from 'date-fns';
import qs from 'qs';
import { toast } from 'react-toastify';
import numeral from 'numeral';

import { numberFormat, dateStringToDate, } from '../../util';
import { startOfMonthByFiscalYears, periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import { getCategory, } from '../../shared/models/accountItem';
import firebase, { functions } from '../../firebase';
import { batch } from '../../shared/firebase';
import { documentTypes, accountItemCategories, accountItemCategoriesByName } from '../../shared/config';
import { generateRows, rowsForExport, computeAlerts, } from '../../shared/lib/individualTrials';
import { presetConsolidationAccountItems, } from '../../shared/models/consolidationAccountItem';
import { balanceFields } from '../../shared/models/trial';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDependentState from '../hooks/useDependentState';
import RelatedCompanyPage from '../hocs/RelatedCompanyPage';
import TrialsSyncButton from '../TrialsSyncButton';
import ProgressButton from '../ProgressButton';
import ExportButton from '../ExportButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import ImportButton from '../ImportButton';
import QuerySelector from '../QuerySelector';
import Alerts from '../Alerts';

const { keys, entries } = Object;
const db = firebase.firestore();

export default RelatedCompanyPage(function CompanyIndividualTrials (props) {
  const { user, period, yearMonth, company, relatedCompany, subsidiaryId, isLockedMonth, } = props;
  const [selectedStartPeriod, selectStartPeriod] = useDependentState(period, [period]);
  const [selectedStartYearMonth, selectStartYearMonth] = useDependentState(fiscalYearOfPeriod(period, relatedCompany.fiscalYears)?.start_date.replace('-', '').slice(0, 6), [period]);
  const [selectedEndPeriod, selectEndPeriod] = useDependentState(period, [period]);
  const [selectedEndYearMonth, selectEndYearMonth] = useDependentState(yearMonth, [yearMonth]);
  const trials = useCollectionSubscription(company.ref.collection('trials').where('subsidiaryId', '==', subsidiaryId).where('yearMonth', '==', yearMonth), [relatedCompany, yearMonth]);
  const trialsByType = keyBy(trials, 'type');
  const accountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', subsidiaryId), [subsidiaryId]);
  const allAccountItems = accountItems;
  const accountItemsByName = keyBy(allAccountItems, 'name');
  const accountItemsByCategoryAndName = keyBy(allAccountItems, _ => [_.account_category, _.name].join('__'));
  const consolidationAccountItems = useCollectionSubscription(company.ref.collection('accountItems').where('subsidiaryId', '==', null), [company]);
  const allConsolidationAccountItems = [...consolidationAccountItems, ...presetConsolidationAccountItems];
  const rows = generateRows(relatedCompany, trials, allAccountItems, allConsolidationAccountItems);
  const periodOptions = (relatedCompany.fiscalYears || []).map(_ => ({ label: `${_.start_date.replace(/-/g, '/').slice(0, 7)} - ${_.end_date.replace(/-/g, '/').slice(0, 7)}`, value: periodOfFiscalYear(_), }));
  const startFiscalYear = fiscalYearOfPeriod(selectedStartPeriod, relatedCompany.fiscalYears) || {};
  const endFiscalYear = fiscalYearOfPeriod(selectedEndPeriod, relatedCompany.fiscalYears) || {};
  const startMonthOptions = startFiscalYear.start_date ? range(formatDate(dateStringToDate(startFiscalYear.start_date), 'yyyyMM'), formatDate(dateStringToDate(startFiscalYear.end_date), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
  const endMonthOptions = endFiscalYear.start_date ? range(formatDate(dateStringToDate(endFiscalYear.start_date), 'yyyyMM'), formatDate(dateStringToDate(endFiscalYear.end_date), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
  const usesIndustry = ['bugyo', 'mf', 'pca'].includes(relatedCompany.externalType) || !!endFiscalYear?.use_industry_template;
  const enabledDocumentTypes = keys(usesIndustry ? documentTypes : omit(documentTypes, 'cr'));
  const [currentDocumentTypes, setCurrentDocumentTypes] = useState(keys(documentTypes));
  const alerts = computeAlerts(relatedCompany, trials, allAccountItems, allConsolidationAccountItems);
  const processRows = (rows) => {
    return rows.map(_ => ({
      ..._,
      account_category_name: accountItemsByName[_.account_item_name]?.account_category,
      account_item_name: _.account_item_name || null,
      closing_balance: numeral(_.closing_balance).value(),
    }));
  };
  const importRows = async (rows) => {
    const duplicateAccountItemNames = entries(countBy(rows.filter(_ => !isEmpty(_.account_item_name)), 'account_item_name')).filter(_ => _[1] > 1).map(_ => _[0]);
    if(duplicateAccountItemNames.length > 0) {
      toast.error(`科目名が重複しています (${duplicateAccountItemNames.join(', ')})`);
      throw new Error('科目名重複');
    }
    const rowsGroupedByType = groupBy(rows, (row) => {
      return get(accountItemCategoriesByName, [row.account_category_name, 'type']);
    });
    const closingDate = endOfMonth(parseDate(yearMonth + '01', 'yyyyMMdd', new Date()));
    const endDate = formatDate(closingDate, 'yyyy-MM-dd');
    const balance = Object.values(
      groupBy(rows.filter(_ => _.account_item_name), _ => accountItemCategoriesByName[_.account_category_name].direction)
    ).map(_ => round(sumBy(_, 'closing_balance'), 5)).reduce((x, y) => x - y);
    if(balance !== 0) {
      toast.error(`貸借不一致です (${numberFormat(relatedCompany.currency, Math.abs(balance))})`);
      throw new Error('貸借不一致');
    }
    await Promise.all(['bs', 'pl', 'cr'].map(async (type) => {
      const id = [subsidiaryId, type, yearMonth].join('__');
      await company.ref.collection('trials').doc(id).set({
        balances: (rowsGroupedByType[type] || []).filter(_ => [_.closing_balance].some(_ => Math.abs(_) > 0)),
        type,
        endDate,
        yearMonth,
        subsidiaryId,
        createdAt: new Date(),
      });
    }));
  };
  const onClickClear = async () => {
    if(!window.confirm('本当にクリアしますか？')) return;

    await batch(db, trials, (batch, trial) => batch.delete(trial.ref));
    toast.success('クリアしました');
  };
  const onClickComputeBalanceKeys = async () => {
    if(!window.confirm('科目コードの紐づいていない行を自動で紐づけます。よろしいですか？')) return;

    await Promise.all(trials.map(async (trial) => {
      await trial.ref.update({
        balances: trial.balances.map((balance) => {
          return {
            ...balance,
            key: balance.key || (
              accountItemsByCategoryAndName[[balance.account_category_name, balance.account_item_name].join('__')]?.shortcut_num
            ) || null,
          };
        }),
      });
    }));
    toast.success('科目コードを紐づけました');
  };
  useEffect(() => {
    if(!startMonthOptions.map(_ => _.value).includes(selectedStartYearMonth)) {
      selectStartYearMonth(null);
    }
  }, [selectedStartPeriod]);
  useEffect(() => {
    if(!endMonthOptions.map(_ => _.value).includes(selectedEndYearMonth)) {
      selectEndYearMonth(null);
    }
  }, [selectedEndPeriod]);

  return props.translate(
    <div className="company-individual-trial">
      <Alerts alerts={alerts} />
      <div className="mb-1 d-flex justify-content-end align-items-end gap-1">
        {
          relatedCompany.externalType !== null ? (
            <Fragment>
              <div className="d-flex gap-1 align-items-end">
                <div className="d-flex gap-1 align-items-center">
                  <div>開始</div>
                  <div style={{ width: 160, position: 'relative', zIndex: 5 }}>
                    <Select
                      value={periodOptions.find(_ => _.value === selectedStartPeriod)}
                      options={periodOptions}
                      onChange={_ => selectStartPeriod(_.value)}
                      components={{ IndicatorsContainer: _ => null }}
                      className="w-100"
                    />
                  </div>
                  <div style={{ width: 90, position: 'relative', zIndex: 5 }}>
                    <Select
                      value={startMonthOptions.find(_ => _.value === selectedStartYearMonth) || null}
                      options={startMonthOptions}
                      onChange={_ => selectStartYearMonth(_.value)}
                      components={{ IndicatorsContainer: _ => null }}
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="d-flex gap-1 align-items-center">
                  <div>終了</div>
                  <div style={{ width: 160, position: 'relative', zIndex: 5 }}>
                    <Select
                      value={periodOptions.find(_ => _.value === selectedEndPeriod)}
                      options={periodOptions}
                      onChange={_ => selectEndPeriod(_.value)}
                      components={{ IndicatorsContainer: _ => null }}
                      className="w-100"
                    />
                  </div>
                  <div style={{ width: 90, position: 'relative', zIndex: 5 }}>
                    <Select
                      value={endMonthOptions.find(_ => _.value === selectedEndYearMonth) || null}
                      options={endMonthOptions}
                      onChange={_ => selectEndYearMonth(_.value)}
                      components={{ IndicatorsContainer: _ => null }}
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
              <div>
                {
                  entries(pick(documentTypes, enabledDocumentTypes)).map(([documentType, { label }]) => {
                    const checked = currentDocumentTypes.includes(documentType);
                    return (
                      <FormGroup check key={documentType}>
                        <Label check>
                          <Input type="checkbox" checked={checked} onChange={_ => _.target.checked ? setCurrentDocumentTypes([...currentDocumentTypes, documentType]) : setCurrentDocumentTypes(currentDocumentTypes.filter(_ => _ !== documentType))} />
                          {label}
                        </Label>
                      </FormGroup>
                    );
                  })
                }
              </div>
              <TrialsSyncButton documentTypes={currentDocumentTypes.filter(_ => enabledDocumentTypes.includes(_))} yearMonth={yearMonth} company={company} companySourceId={relatedCompany.sourceId} subsidiaryId={subsidiaryId} subsidiaryStartPeriod={selectedStartPeriod} subsidiaryStartYearMonth={selectedStartYearMonth} subsidiaryEndPeriod={selectedEndPeriod} subsidiaryEndYearMonth={selectedEndYearMonth} fiscalYears={relatedCompany.fiscalYears} relatedCompany={relatedCompany} disabled={!(selectedStartPeriod && selectedStartYearMonth && selectedEndPeriod && selectedEndYearMonth) || isLockedMonth} admin={user.admin} />
            </Fragment>
          ) : (
            <ImportButton processRows={processRows} beforeSave={importRows} documentName="trial" fields={balanceFields({ accountItems: allAccountItems })} disabled={isLockedMonth} importKey={`trials__${relatedCompany.id}__${yearMonth}`} />
          )
        }
        {
          ['bugyo', 'pca'].includes(relatedCompany.externalType) && trials.some(_ => _.balances.some(_ => _.key == null)) && (
            <ProgressButton color="info" process={onClickComputeBalanceKeys}>
              自動で科目コードを紐づける
            </ProgressButton>
          )
        }
        <ExportButton fileName={`試算表_${yearMonth}.csv`} rows={rowsForExport(relatedCompany, trials, allAccountItems, allConsolidationAccountItems)} />
        <ProgressButton color="danger" process={onClickClear} disabled={isLockedMonth}>
          <span className="fas fa-eraser mr-1" />
          試算表をクリア
        </ProgressButton>
      </div>
      <div className="mb-1 d-flex justify-content-end gap-3">
        {
          relatedCompany.externalType !== null && entries(documentTypes).map(([documentType, { label }]) => {
            const trial = trialsByType[documentType];
            return trial != null && (
              <div className="text-muted small d-flex justify-content-end">
                {label} 最終同期日時: {formatDate((trial.syncedAt || trial.createdAt).toDate(), 'yyyy/MM/d HH:mm:ss')} ({[trial.startDate, trial.endDate].map(_ => _?.slice(0, 7).replace('-', '/')).join(' - ')}試算表)
              </div>
            );
          })
        }
      </div>
      <div>
        <table className="table sticky-table">
          <thead className="thead-light text-center">
            <tr>
              <th>カテゴリー</th>
              <th>科目</th>
              <th>連結科目</th>
              <th>残高</th>
            </tr>
          </thead>
          <tbody>
            {
              rows.map((row, i) => {
                const { id, accountItem, consolidationAccountItem, account_category_name: categoryName, closing_balance: closingBalance, } = row;
                const isCategory = accountItem == null;

                return (
                  <tr key={i} style={{ fontWeight: isCategory ? 700 : 400 }}>
                    <td>
                      {categoryName}
                    </td>
                    <td>
                      {accountItem?.name || categoryName}
                    </td>
                    <td>
                      {consolidationAccountItem?.name}
                    </td>
                    <td className="text-right">
                      {numberFormat(relatedCompany.currency, closingBalance)}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
