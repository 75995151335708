import React, { useState } from 'react';
import { keyBy, isEmpty, mapValues, sortBy, pick, omit, get, } from 'lodash';
import { Button, Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { isEmail } from 'validator';
import copy from 'copy-to-clipboard';
import Select from 'react-select';
import { useAsync } from 'react-use';

import firebase, { functions } from '../../firebase';
import { screens, pkgScreens, } from '../../shared/config';
import { canUpdateMember } from '../../shared/abilities';
import { fields, addingFields, } from '../../shared/models/member';
import { fields as roleTemplateFields, } from '../../shared/models/roleTemplate';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useFormState from '../hooks/useFormState';
import ModelFormModal from '../modals/ModelFormModal';
import SettingsPage from '../hocs/SettingsPage';
import ModalButton from '../ModalButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import Field from '../Field';
import HelpLink from '../HelpLink';
import { roles } from '../../shared/models/user';

const db = firebase.firestore();
const usersRef = db.collection('users');
const { entries } = Object;
const roleOptions = entries(roles).map(([k, v]) => ({ label: v, value: k, }));
const addCompanyMember = functions.httpsCallable('addCompanyMember');
const getCompanyMembers = functions.httpsCallable('getCompanyMembers');

export default SettingsPage(function CompanyMembers (props) {
  const { user, company, match: { params: { companyId } } } = props;
  const subsidiaries = useCollectionSubscription(company.ref.collection('subsidiaries').orderBy('index'), [company]);
  const relatedCompanies = [company, ...subsidiaries];
  const roleTemplates = useCollectionSubscription(company.ref.collection('roleTemplates').orderBy('createdAt'), [company]);
  const roleTemplatesById = keyBy(roleTemplates, 'id');
  const { value: members = [] } = useAsync(async () => {
    const { data: members, } = await getCompanyMembers({ companyId });
    return sortBy(members, [_ => Object.keys(roles).indexOf(_.role), 'email']);
  }, [company]);
  const [invitationToken, setInvitationToken] = useState('');
  const invitationUrl = `${window.location.origin}/confirmation?token=${invitationToken}&signUp=1`;
  const onClickCopy = (text) => {
    copy(text);
    toast.success('クリップボードにコピーしました');
  };
  const onClickRemove = async (member) => {
    if(!window.confirm('本当に除外しますか？')) return;

    await company.ref.update({ users: omit(company.users, member.id), });
    // NOTE: 上のupdateだけだと、client側のcompanyの変更が早く動いてしまい、getCompanyMembers関数が返すものが古いものになってしまう
    await company.ref.update({ updatedAt: new Date(), });
    toast.success('除外しました');
  };
  const onChangeRole = async (member, { value: role } = {}) => {
    if(role === 'owner' && !window.confirm('オーナーを変更しますか？（現在のオーナーは管理者になります）')) return;

    await company.ref.update({
      [`users.${member.id}.role`]: role,
      ...(
        role === 'owner' && {
          [`users.${members.find(_ => _.role === 'owner').id}.role`]: 'admin',
        }
      ),
    });
    // NOTE: 上のupdateだけだと、client側のcompanyの変更が早く動いてしまい、getCompanyMembers関数が返すものが古いものになってしまう
    await company.ref.update({ updatedAt: new Date(), });
    toast.success('更新しました');
  };
  const renderFormHeader = (statedFields) => {
    const onFixed = (value) => {
      const roleTemplate = roleTemplatesById[value];
      statedFields.setValues({
        ...pick(roleTemplate, [
          'role',
          'enabledScreens',
          'enabledAllRelatedCompanies',
          'enabledRelatedCompanyIds',
          'enabledPkgScreens',
          'approvable',
        ]),
      });
    };

    return (
      <div className="d-flex justify-content-end">
        <div className='card p-2 mb-3'>
          <div className='text-muted small'>権限テンプレート</div>
          <TemplateSelector onFixed={onFixed} roleTemplates={roleTemplates} />
        </div>
      </div>
    );
  };

  return props.translate(
    <div className="company-members container">
      <div className="d-flex justify-content-end mb-3">
        <HelpLink text="メンバー管理" />
      </div>
      {
        canUpdateMember(company, user) && (
          <div className="d-flex justify-content-end gap-1">
            <ModalButton title="権限テンプレート" content={_ => <RoleTemplates user={user} company={company} relatedCompanies={relatedCompanies} />} modalProps={{ style: { minWidth: 1000 } }}>
              権限テンプレート
            </ModalButton>
            {
              members.length >= (company.licenseCount || 10) ? (
                <div className="flex-fill alert alert-warning">
                  メンバー数の上限に達しています。
                </div>
              ) : (
                // <AddingForm company={company} user={user} setInvitationToken={setInvitationToken} />
                <ModalButton title="追加する" content={_ => <AddingFormModalContent {..._} company={company} user={user} relatedCompanies={relatedCompanies} setInvitationToken={setInvitationToken} renderFormHeader={renderFormHeader} />}>
                  <span className="fas fa-plus mr-1" />
                  追加する
                </ModalButton>
              )
            }
          </div>
        )
      }
      {
        invitationToken && (
          <div className="alert alert-info my-2">
            <div>存在しないユーザーです。以下の招待URLからユーザー登録を依頼してください。</div>
            <div style={{ width: 500 }} className="mt-1 d-flex">
              <Input className="flex-grow-1 mr-2" readOnly defaultValue={invitationUrl} />
              <Button color="primary" onClick={onClickCopy.bind(null, invitationUrl)}>
                <span className="fas fa-copy" />
              </Button>
            </div>
          </div>
        )
      }
      <table className="table mt-4">
        <tbody>
          {
            members.map((member) => {
              const { id, role, enabledScreens, enabledAllRelatedCompanies, enabledRelatedCompanyIds, enabledPkgScreens, approvable, } = member;
              const onSubmitEdit = async (toggleModal, values) => {
                if(role !== 'owner' && values.role === 'owner' && !window.confirm('オーナーを変更しますか？（現在のオーナーは管理者になります）')) return;

                await company.ref.update({
                  [`users.${id}`]: values,
                  ...(
                    values.role === 'owner' && {
                      [`users.${members.find(_ => _.role === 'owner').id}.role`]: 'admin',
                    }
                  ),
                });
                // NOTE: 上のupdateだけだと、client側のcompanyの変更が早く動いてしまい、getCompanyMembers関数が返すものが古いものになってしまう
                await company.ref.update({ updatedAt: new Date(), });
                toast.success('更新しました');
                toggleModal(false);
              };

              return (
                <tr key={id}>
                  <td>
                    <div className="d-flex gap-1 align-items-end">
                      <div>{member.displayName}</div>
                      <div className="text-muted small">&lt;{member.email}&gt;</div>
                    </div>
                  </td>
                  <td>
                    <div>{roles[role]}</div>
                    {approvable && <div className="badge badge-info">仕訳承認可</div>}
                  </td>
                  <td className="text-right text-nowrap">
                    <ModalButton Modal={ModelFormModal} modalProps={({ toggleModal }) => ({ title: '編集', fields: fields({ company, isSelf: user.id === id, currentRole: role, relatedCompanies, }), values: { role, enabledScreens, enabledAllRelatedCompanies, enabledRelatedCompanyIds, enabledPkgScreens, approvable, }, onSubmit: onSubmitEdit.bind(null, toggleModal), renderFormHeader, })}>
                      <span className="fas fa-edit mr-1" />
                      編集
                    </ModalButton>
                    {
                      role !== 'owner' && user.id !== id && canUpdateMember(company, user) && (
                        <Button className="ml-1 bg-danger text-light" onClick={onClickRemove.bind(null, { ...member, role })}>
                          <span className="fas fa-trash mr-1" />
                          除外
                        </Button>
                      )
                    }
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
});

function AddingForm (props) {
  const { company, user, setInvitationToken, } = props;
  const [role, setRole] = useState('member');
  const [email, setEmail] = useState('');
  const [isAdding, toggleAdding] = useToggle();
  const onSubmitAddingForm = async (event) => {
    event.preventDefault();
    toggleAdding();
    try {
      const { data: { type, user: addedUser, token, } } = await addCompanyMember({ companyId: company.id, email, role, });
      ({
        add: () => {
          toast.success('追加しました');
        },
        invite: () => {
          setInvitationToken(token);
        },
      })[type]();
      setEmail('');
      setRole('member');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleAdding();
  };

  return (
    <Form onSubmit={onSubmitAddingForm} className="d-flex">
      <div style={{ width: 200 }}>
        <Select
          options={roleOptions}
          value={roleOptions.find(_ => _.value === role)}
          onChange={_ => setRole(_.value)}
          className="w-100"
        />
      </div>
      <Input className="ml-2" value={email} onChange={_ => setEmail(_.target.value)} style={{ width: 300 }} placeholder="メールアドレス" />
      <Button className="ml-2" onClick={onSubmitAddingForm} disabled={isAdding || !isEmail(email)}>
        <span className="fas fa-plus mr-1" />
        追加する
      </Button>
    </Form>
  );
}

function AddingFormModalContent (props) {
  const { company, user, toggleModal, setInvitationToken, relatedCompanies, renderFormHeader, } = props;
  const statedFields = useFormState(null, addingFields({ relatedCompanies }), false);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    toggleSubmitting(true);
    const values = mapValues(statedFields, 'value');
    const { data: { type, user: addedUser, token, } } = await addCompanyMember({ companyId: company.id, ...values, });
    const message = ({
      exists: 'すでにメンバーです',
      add: '追加しました',
      invite: '招待URLを発行しました',
    })[type];
    toast[type === 'exists' ? 'error' : 'success'](message);
    if(type === 'invite') {
      setInvitationToken(token);
    }
    toggleSubmitting(false);
    toggleModal(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      {renderFormHeader?.(statedFields)}
      {
        entries(statedFields).map(([fieldName, fieldSetting]) => (
          <Field
            key={fieldName}
            name={fieldName}
            values={mapValues(statedFields, 'value')}
            {...fieldSetting}
          />
        ))
      }
      <Button block className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>追加する</Button>
    </Form>
  );
}

function RoleTemplates (props) {
  const { user, company, relatedCompanies, } = props;
  const relatedCompaniesById = keyBy(relatedCompanies, 'id');
  const roleTemplates = useCollectionSubscription(company.ref.collection('roleTemplates').orderBy('createdAt'), [company]);

  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <AddButton itemRef={company.ref.collection('roleTemplates').doc()} FormModal={ModelFormModal} formProps={{ title: '権限テンプレート追加', fields: roleTemplateFields({ relatedCompanies }), }} />
      </div>
      <table className="table">
        <thead className="thead-light text-center">
          <tr>
            <th style={{ minWidth: 200 }}>名称</th>
            <th style={{ minWidth: 150 }}>権限</th>
            <th style={{ minWidth: 400 }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            roleTemplates.map((roleTemplate) => {
              const { id, ref, name, role, enabledScreens, enabledAllRelatedCompanies, enabledRelatedCompanyIds, enabledPkgScreens, } = roleTemplate;
              return (
                <tr key={id}>
                  <td>
                    {name}
                  </td>
                  <td>
                    {roles[role]}
                  </td>
                  <td>
                    <div className="d-flex flex-column gap-1">
                      <div className="card p-2">
                        <h6>アクセス可能な画面</h6>
                        <div>
                          {enabledScreens?.map((_, i) => <span key={i} className="badge badge-secondary">{screens[_]?.label}</span>)}
                        </div>
                      </div>
                      {
                        enabledScreens?.includes('relatedCompanies') && (
                          <div className="card p-2">
                            <h6>アクセス可能な会社</h6>
                            {enabledAllRelatedCompanies && <div><span className="badge badge-info">全ての会社</span></div>}
                            <div>
                              {enabledRelatedCompanyIds?.map((_, i) => <span key={i} className="badge badge-secondary">{relatedCompaniesById[_]?.display_name}</span>)}
                            </div>
                          </div>
                        )
                      }
                      {
                        !isEmpty(enabledPkgScreens) && (
                          <div className="card p-2">
                            <h6>アクセス可能な全社連結PKG画面</h6>
                            <div>
                              {enabledPkgScreens?.map((_, i) => <span key={i} className="badge badge-secondary">{pkgScreens[_]?.label}</span>)}
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </td>
                  <td className="text-nowrap text-right">
                    <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ documentName: 'twoDimensionsSetting', title: '権限テンプレート編集', fields: roleTemplateFields({ relatedCompanies }), }} />
                    <DeleteButton itemRef={ref} className="ml-1" />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
}

function TemplateSelector(props) {
  const { roleTemplates } = props;
  const [value, setValue] = useState(null);
  const roleTemplateOptions = roleTemplates.map((_) => ({ label: _.name, value: _.id }));
  const onFixed = async () => {
    if (!window.confirm('適用しますか？')) return;

    await props.onFixed(value);
    setValue(null);
  };

  return (
    <div className='d-flex gap-1' style={{ minWidth: 250, }}>
      <Select
        value={roleTemplateOptions.find((_) => _.value === value)}
        options={roleTemplateOptions}
        onChange={(_) => setValue(_ && _.value)}
        isClearable
        className='flex-fill'
      />
      <Button onClick={onFixed} disabled={value == null}>
        適用
      </Button>
    </div>
  );
}
