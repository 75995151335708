const { omit, isEmpty, inRange, range, } = require('lodash');
const { format: formatDate, } = require('date-fns');

const { periodOfFiscalYear, fiscalYearOfPeriod, } = require('../util');
const { currencies, externalTypes, } = require('../config');

const { entries } = Object;

const fieldsToAddFromExternal = ({ externalCompanies = [] } = {}) => {
  return {
    sourceId: {
      label: '事業所',
      type: 'select',
      options: externalCompanies.map(_ => ({ label: `${_.display_name || _.name} (${_.id})`, value: _.id })),
      validations: {
        required: v => v != null,
      },
    },
  };
};

const fieldsToAdd = () => {
  return {
    display_name: {
      label: '名称',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const fieldsToEdit = ({ isParent = false, company, relatedCompany, } = {}) => {
  const monthOptions = (fieldName) => (values) => {
    const fiscalYear = fiscalYearOfPeriod(values[fieldName], company.fiscalYears);
    return fiscalYear?.start_date ? range(formatDate(new Date(fiscalYear.start_date), 'yyyyMM'), formatDate(new Date(fiscalYear.end_date), 'yyyyMM') + '.1').filter(_ => inRange(parseInt(_.toString().slice(-2), 10), 1, 12.1)).map(_ => ({ label: `${_.toString().slice(0, 4)}/${_.toString().slice(4)}`, value: _.toString() })) : [];
  };

  return {
    currency: {
      label: '通貨',
      type: 'select',
      options: entries(currencies).map(([k, v]) => ({ label: v.label, value: k })),
      initialValue: 'jpy',
      readOnly: _ => isParent,
      validations: {
        required: v => v != null,
      },
    },
    externalType: {
      label: '連携',
      type: 'select',
      options: entries(externalTypes).map(([k, v]) => ({ label: v.label, value: k, })),
    },
    sourceId: {
      label: '連携先での事業所ID',
      type: 'string',
      hidden: _ => _.externalType == null || _.externalType === 'pca',
      validations: {
        required: (v, s) => s.externalType === 'bugyo' || v != null || !isEmpty(v),
      },
    },
    disabledItemTrials: {
      label: '品目別試算表を利用しない',
      type: 'boolean',
      hidden: _ => _.externalType !== 'freee',
      initialValue: false,
    },
    joinPeriod: {
      label: '連結対象になった年度',
      type: 'select',
      options: (company.fiscalYears || []).map(_ => ({ label: `${_.start_date.replace(/-/g, '/').slice(0, 7)} - ${_.end_date.replace(/-/g, '/').slice(0, 7)}`, value: periodOfFiscalYear(_), })),
    },
    joinYearMonth: {
      label: '連結対象になった年月',
      type: 'select',
      options: monthOptions('joinPeriod'),
      hidden: _ => _.joinPeriod == null,
      validations: {
        required: _ => _ != null
      },
    },
    isStartJoin: {
      label: '月初から連結対象',
      type: 'boolean',
      hidden: _ => _.joinPeriod == null,
    },
    exceptPeriod: {
      label: '連結除外になった年度',
      type: 'select',
      options: (company.fiscalYears || []).map(_ => ({ label: `${_.start_date.replace(/-/g, '/').slice(0, 7)} - ${_.end_date.replace(/-/g, '/').slice(0, 7)}`, value: periodOfFiscalYear(_), })),
    },
    exceptYearMonth: {
      label: '連結除外になった年月',
      type: 'select',
      options: monthOptions('exceptPeriod'),
      hidden: _ => _.exceptPeriod == null,
      validations: {
        required: _ => _ != null
      },
    },
  };
};

const sectionFields = ({ consolidationSegments = [], } = {}) => {
  return {
    noneConsolidationSegmentId: {
      label: '部門未選択の連結セグメント',
      type: 'select',
      options: consolidationSegments.map(_ => ({ label: _.name, value: _.id, })),
    },
  };
};

const pcaIntegrationFields = ({ pcaDataCenters }) => {
  return {
    isHyper: {
      label: 'hyper',
      type: 'boolean',
      initialValue: false,
    },
    dataCenterCode: {
      label: 'データセンター',
      type: 'select',
      options: entries(pcaDataCenters).map(([k, v]) => ({ label: v.label, value: k })),
      validations: {
        required: _ => _ != null
      },
    },
  };
};

exports.sectionFields = sectionFields;
exports.fieldsToEdit = fieldsToEdit;
exports.fieldsToAdd = fieldsToAdd;
exports.fieldsToAddFromExternal = fieldsToAddFromExternal;
exports.pcaIntegrationFields = pcaIntegrationFields;
